<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Receipts': 'Comprovantes',
    },
    es: {
      'Receipts': 'Comprovantes',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: true,

      receipt: {
        id: this.$route.params.id
      }
    };
  },
  methods: {
    getReceipt: function () {
      api
        .get('partners/receipts/' + this.receipt.id)
        .then((response) => {
          if (response.data.status == 'success') {
            this.receipt = response.data.receipt
          }
        })
    }
  },
  mounted() {
    this.getReceipt()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Receipts') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
